@font-face {
  font-family: 'DM Sans';
  src: url('DMSans-Bold.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('DMSans-BoldItalic.ttf') format('ttf');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('DMSans-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('DMSans-MediumItalic.ttf') format('ttf');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('DMSans-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DM Sans';
  src: url('DMSans-Italic.ttf') format('ttf');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
