@import './variables.scss';
@import './_mixins.scss';
@import './fonts/index.css';

/* Important! */
/* do not import additional styles here and do not use global styles to style components. */
/* CSS modules should be used for that (just name the file MyComponent.module.scss and import from JS */

html, body {
  width: 100% !important;
  min-width: $main-column-min-width !important;
  height: 100%;
  margin: 0;
  font-size: 14px;
  background-color: $page-background;
  font-family: 'Roboto', 'Roboto Slab', 'DM Sans', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji' !important;
  cursor: default;
  color: $text-color-main !important;

  ::-webkit-scrollbar {
    width: 16px;
  }
  ::-webkit-scrollbar-button {
    padding: 0;
  }
  ::-webkit-scrollbar-thumb {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: $color-gray;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

body {
  --scrollbar-offset: 0;

  @media screen {
    --scrollbar-offset: calc(100vw - 100%);
  }
}

/* Prevent margins of last element to fall out of <body> and make scrollbars visible */
body:before,
body:after {
  content: '';
  float: none;
  clear: both;
  display: table;
}
