@import '~src/assets/variables';

.MuiModal-root {
  overflow: auto;
  padding-bottom: 10px;
}

.MuiDrawer-paper {
  background-color: transparent;
  border-right: none;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 1px 3px 1px rgba(0, 0, 0, 0.15);
  overflow-x: hidden;
}

// -- fix it --
.MuiMenu-paper {
  margin-top: 4px;
  margin-left: 44px;
  min-width: 200px !important;
  background: #F3EDF7;
  box-shadow: 0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
}
.MuiMenuItem-root.Mui-selected,
.MuiMenuItem-root.Mui-selected:hover,
.MuiMenuItem-root:hover {
  background-color: inherit !important;
}
// -- fix it --

.MuiListItemButton-root {
  padding: 8px 12px;
}

.MuiListItem-root:hover {
  background-color: inherit;
}

.MuiOutlinedInput-notchedOutline {
  height: 56px;
  border-radius: 12px;
}

.Mui-focused {
  fieldset {
    border-color: $text-color-crimson !important;
  }
}

.MuiOutlinedInput-root {
  &:hover fieldset {
    border-width: 2px;
    border-color: $text-color-crimson !important;
  }
}

.Mui-disabled {
  &:hover fieldset {
    border-width: 1px !important;
    border-color: $color-gray !important;
    opacity: 0.5;
  }
}

.MuiTabs-flexContainer {
  button {
    flex: 1;
  }
}

.MuiInputLabel-root.Mui-focused {
  color: $text-color-crimson;
}

.MuiCheckbox-root.Mui-checked {
  color: $text-color-crimson;
}

.MuiCheckbox-root:hover {
  background: transparent;
}

.MuiInputLabel-root.Mui-error {
  color: $text-color-error;
}

.MuiTabs-root {
  min-height: 49px;
  border-bottom: 1px solid #CCCCCC;
}

.MuiTab-root {
  text-transform: none;
  color: inherit !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  padding: 0 32px;
}

.MuiTabs-flexContainer button {
  flex: none;
}

.MuiTabs-indicator {
  background-color: $background-color-crimson;
}

.MuiFormHelperText-root.Mui-error {
  color: $text-color-error;
  position: absolute;
  margin-top: 55px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
}