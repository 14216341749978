@font-face {
    font-family: 'Roboto Slab';
    src: url('RobotoSlab-Regular.eot');
    src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'),
        url('RobotoSlab-Regular.eot?#iefix') format('embedded-opentype'),
        url('RobotoSlab-Regular.woff2') format('woff2'),
        url('RobotoSlab-Regular.woff') format('woff'),
        url('RobotoSlab-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('RobotoSlab-Bold.eot');
    src: local('Roboto Slab Bold'), local('RobotoSlab-Bold'),
        url('RobotoSlab-Bold.eot?#iefix') format('embedded-opentype'),
        url('RobotoSlab-Bold.woff2') format('woff2'),
        url('RobotoSlab-Bold.woff') format('woff'),
        url('RobotoSlab-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('RobotoSlab-Light.eot');
    src: local('Roboto Slab Light'), local('RobotoSlab-Light'),
        url('RobotoSlab-Light.eot?#iefix') format('embedded-opentype'),
        url('RobotoSlab-Light.woff2') format('woff2'),
        url('RobotoSlab-Light.woff') format('woff'),
        url('RobotoSlab-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto Slab';
    src: url('RobotoSlab-Thin.eot');
    src: local('Roboto Slab Thin'), local('RobotoSlab-Thin'),
        url('RobotoSlab-Thin.eot?#iefix') format('embedded-opentype'),
        url('RobotoSlab-Thin.woff2') format('woff2'),
        url('RobotoSlab-Thin.woff') format('woff'),
        url('RobotoSlab-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

